@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Khand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;700;800&display=swap');

body, p, a, h1, h2, h3, h4{
  font-family: 'Nunito' !important;
  font-weight: 500;
}

.App {
  text-align: center;
}
a, 
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#04c6d0;
}
a:hover,a:focus, 
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover{
  color:#10aab2;
}
.ant-tabs-ink-bar, 
.ant-btn-primary,
.ant-checkbox-checked .ant-checkbox-inner{
background:#04c6d0;
}
.ant-btn-primary,
.ant-checkbox-checked .ant-checkbox-inner{
  border-color: #04c6d0;
}
.ant-btn-primary:focus,.ant-btn-primary:hover{
background: #10aab2;
border-color: #10aab2;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
h2{
  font-size: 26px;
  font-weight: 700;
  color:#282c34;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-list > .ant-col > .ant-row{
  margin:0px 0px 25px 0px;
}

.ant-tabs-nav{
  background:#fff;
  z-index:99;
}
.menuItem-title{
  position: relative;
  font-size:17px;
}

.menuItem-title:after{
  border-top: 1px dotted #b5b5b5;
  content: '';
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 30px;
}
.menuItem-title h3{
  display: inline-block;
  position: relative;
  background: #fff;
  z-index: 10;
  padding-right: 10px;
  margin-bottom:5px;
}
.menuItem-dots{
  background-image: linear-gradient(to right, rgba(56, 47, 47, 0.5) 33%, rgba(0, 0, 0, 0) 0%);
    background-position: bottom;
    background-size: 3px 1px;
    background-repeat: repeat-x;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 20px;
}
.menuItem-price{
  position: absolute;
  right: 0;
  padding-left: 10px;
  background: #fff;
  z-index: 10;
}
h5.menu-category{
  font-family: 'Tangerine', cursive;
  color:#E9B947;
  font-size:40px;
  text-align:center;
  margin-top:20px;
}
.chefSelection-item > .ant-row{
  padding: 8px 15px 0 15px;
}
.chefSelection-item,
.chefSelection-item .menuItem-title h3,
.chefSelection-item .menuItem-price{
  background:#f8f3ea;
}
.ant-layout-sider, 
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub,
.ant-layout-sider-zero-width-trigger{
  background: #171e2b;
}
.ant-layout-header{
  background: #f0f2f5;
  height:40px;
}

.ant-layout-sider-children .logo{
 margin:14px;
 height:50px;
}
.ant-layout-sider-collapsed .logo, 
.ant-layout-sider-below .logo,
.ant-layout-sider-zero-width .logo{
  margin-left:50px
}
.ant-layout-sider-children .logo img{
  height: 45px
}
.ant-layout-sider-children .logo h1{
  color:#fff;

}
.login-form {
  margin-top:10px
}
.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
  margin: 0px 0px 10px 0;
}
.ant-layout-sider{

}
.ant-layout-sider-zero-width-trigger{
  top:11px;
  left:0;
}
.logo h1{
  color:#fff;
  padding-left:10px;
}
.ant-layout{
  background:#f0f2f5;
}
.site-layout{
  margin-left:200px;
}
.ant-layout-content{
}
.bgColor .ant-layout{
  margin-left:200px;
}
.ant-layout.sider-collapsed{
  margin-left:0 !important;
}
.bgColor .ant-layout-content .site-layout-background{
  background:#f0f2f5;
  box-shadow: none;

}
.ant-layout-content .site-layout-background{
  background:#fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius:10px;
}
.ant-card-bordered{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; 
  border-radius: 10px;
}
.app-logo{
  text-align: center;
    padding: 15px;
    background: #171e2b;
}
.app-logo img{
  width:130px;
}
.page-title{
  margin:30px 0 35px 0;
  font-size:26px;
  text-align: center;
}
.menuItem-img{
  padding-top:5px;
}
.menu-search{
  margin-right:15px;
}
.container-menu{
  padding:0px;
}
.menuItem-description{
  max-height:45px;
  overflow:hidden;
  margin-bottom:0px;

}
.mt3 .menuItem-name{
  position: relative;
  font-family: 'Khand', sans-serif;
  font-size:20px;
  margin-bottom:0px;
}
.mt3 .menuItem-price{
  position: relative;
  padding-left: 0px;
  font-size:16px;
}
.btnAdd{
  margin-bottom:20px;
  padding-top:5px;

}
.ant-form-item textarea{
  font-size:16px;
}
.uploadedImage{
  max-width:200px;
}
.uploadedImage.portrait{
  max-height:150px;
}
.uploadedImage:hover{
  opacity:0.7;
}
.uploadedImage-min{
  max-width:150px;
}
.thumbnailList{
  width:100px;
}
.tableSearchField{
  width: auto;
  float:right;
  margin-bottom:10px;
}
.tableAction{
  margin-right:10px;

}
.tableIcon{
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right:7px;
}
.iconFree{
  background-color: rgb(11, 155, 71);
}
.iconBusy{
  background-color: rgb(204, 22, 22);
}
.formCollapse{
  margin-bottom:40px;
}
.ant-collapse-header{
  font-size:17px;
  font-weight: bold;
}
.formCollapse.formDrawer .ant-collapse-header,
.formCollapse.formDrawer .ant-collapse-content-box{
  padding:12px 0;
}
.subFormCollapse.ant-collapse-borderless > .ant-collapse-item{
  border:0;
}
.subFormCollapse .ant-collapse-header{
  font-size:15px;
  font-weight: bold;
  padding-left: 0px !important;
}
h3.addIngredientLabel{
  margin-bottom: 0;
  font-weight: 600;
}
.inputAdvanced{
  margin-left:10px;
}
.inputSmall{
  width:80px;
  display:inline;
  margin-right:20px
}
.formItemInline{
  display: 'inline-block';
  margin-right:10px;
}
.coverImagePublicContainer{
  height:300px;
  position:relative;
}
.coverImagePublic{
  width:100%;
  object-fit: cover;
  height:300px;
}
.logoImagePublic{
  position:absolute;
  left:50%;
  bottom:-40px;
  width:200px;
  height:200px;
  object-fit: cover;
  border-radius:100px;
  border:20px solid #fff;
  z-index:11;
}
.menuItemImage{
  width:100%;
  height:150px;
  object-fit: cover;
  border-radius: 10px;
}
.menuCategorySection{
  margin: 0px 0px 20px 0;
  padding-bottom:50px;
  width:100%;
  border-bottom:5px solid #efefef;
}

.cardButton{
  color:#1c6cb6 !important;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label{
  padding:0;
}
.ant-form-item{
  margin-bottom:20px;
}
.restaurantHeaderTitle{
  font-size:36px;
  background:#ffffff;
  position:relative;
  margin:-10px 0px 10px 10px;
  z-index:12;
  padding:5px 25px 15px 25px;
  display: inline-block;

}
.restaurantMenuContainer{
  margin-top:30px !important;
  padding:0px 15px;
}
.restaurantContainer{
  background: #f3f7f8;
}
.contentRow{
  background:#fff;
}
.restaurantSectionContainer{
  padding:0px 15px;
}
.ant-picker-time-panel-column{
  padding-bottom: 10px !important;
}
.ant-picker-time-panel-column:after{
  height:1px !important;
}
.dropdownRestaurants{
  margin-right: 30px;
}
.dropdownRestaurants a{
  font-weight:500;
  color:#171e2b;
}
.spacingTop{
  margin-top:40px;
}
.spacingBottom{
  margin-bottom: 15px
}
.spacingVertical{
  margin:30px 0;
}
h4.widgetTitle{
  text-transform: uppercase;
  font-size:12px;
  color:#6d819e;
  margin-bottom:15px;
}
.widgetContent{
  margin-top:15px;
}
.widgetNumber{
  font-size:40px;
  padding-right:10px;
  line-height:42px;
}
.iconBg{
  background:#e0eafc;
  border-radius: 50%;
  padding:10px;
}
.iconBg svg{
  width: 30px;
  height: 30px;
  color: #3f80ea!important;
}
.ant-breadcrumb a{
  color:#495057;
}
.widgetRestaurantCoverimage{
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}
.ant-layout-footer{
  background:#fff;
}
.ant-collapse-borderless{
  background-color: #fff;
}
.rowGutterCard{
  margin-bottom: 16px;
}
.rowGutterCard .ant-col{

}
.status{

}
.rowClickable{
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 16px;
}
.status.statusActive{}
.rowDragging{
  background: #fafafa;
  border: 1px solid #ccc;
}
.rowDragging td {
  padding: 16px;
}

.rowDragging .drag-visible {
  visibility: visible;
}
.modalFooter{
  display: flex;
  justify-content: space-between;
}
.bottomSpacing{
  margin-top:100px;
}
.alignCenter{
  text-align: center;
  padding:10px 0;
}
.footerNote{
  color:#888;
}
.qrCodeTemplateContainer{
  margin-top:20px;
  margin-bottom:40px;
}
.qrCodeTemplate{
  position:relative;
  background:url('./assets/img/placecard-qr-template.png') no-repeat;
  background-size: 1336px 1844px;
  height:1844px;
  width:1336px;
}
.qrCodeTemplate.qrCodeTemplateEN{
  background:url('./assets/img/placecard-qr-template_en.png') no-repeat;
}
.qrCodeTemplateValue{
  position: absolute;
  top: 323px;
  left: 705px;
}
.qrCodeTemplateTableIndex{
  position: absolute;
  left: 190px;
  top: 120px;
  width:1000px;
  font-size: 45px;
  text-align: center;
  color:#fff;
  font-family: 'Nunito';
}
.qrCodeTemplateQRImage{
  position: absolute;
  left: 860px;
  top: 478px;
}
.qrCodeTemplateQRImage img{
  width: 150px;
}
.qrCodeTemplateHotspot{
  position: absolute;
  bottom:330px;
  width:1100px;
  left:120px;
  font-size: 35px;
  color:#292333;
  display: flex;
  justify-content: center;
  align-items:center;
}
.qrCodeTemplateHotspot .hotspotSsid{
  text-align: center;
  margin-right: 90px;
  margin-left:70px;
  font-size: 45px;
} 
.qrCodeTemplateHotspot .hotspotIcon{
  width: 48px;
  height: 40px;
}
.qrCodeTemplateHotspot .labelText{
  color:#645A77;
  font-size: 30px;
  text-align: center;
  margin-bottom: -10px;
  text-transform: uppercase;
}
.qrCodeToImg{
  height:0px;
  overflow:hidden;
}

.locationSelector{
  margin: 15px 0px 35px 25px;
}
.locationSelector a{
  font-weight: bold;
  color:#ffffff;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  align-items: center;
  font-size:15px;
}
.locationSelector .iconStyle{
  font-size: 16px;
}
.ant-layout-sider-children{
  flex-direction: column;
  display: flex;
}
.leftSiderContainer{

}
.leftSider{
  flex-direction: column;
  justify-content: space-between;
  display:flex;
  height:100%;
  margin-top:20px;
  overflow: auto;
}

.ant-layout.authLayout{
  background: #171e2b;
  padding-top:80px;
  min-height:100vh;
}
.authFormContainer{
  background:#fff;
  padding:50px 30px 50px 30px;
  border-radius:10px;
}
.authFormContainer .authLogo{
  text-align: center;

}
.mapMarker{
  background:url('./assets/img/map-marker-icon.png');
  width:20px;
  height:20px;
  display:inline-flex;
  padding: 30px 30px;
  text-align: 'center';
  align-items: 'center';
  justify-content: 'center';
  transform: translate(-50%, -50%);
}
.actionLink{
  margin:5px 0px 10px 0;
  display:block;
}
.actionLinkIcon{
  margin-bottom:15px;
  margin-top:10px;
}
.actionLinkIcon a{
  margin-left:5px;
}
.ant-spin-dot-item {
  background-color: #04c6d0;
}
.ant-input,
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker,
.ant-input-affix-wrapper,
.ant-input-number{
  border-radius: 5px;
}
.ant-input-prefix{
  margin-right:8px;
}
.onBoardingContainer{
  background:#fff;
  padding:20px;
}
.onBoardingTimeline{
  margin-top:30px;
}
.onBoardingTitle{
  margin-bottom:50px;
  margin-top:15px;
}
.onBoardingItem{
  margin-bottom:10px;
  font-size:15px;
}
.onBoardingItem > span{
  margin-right:15px;
}
.onBoardingItem.active{
  font-size:18px;
  font-weight:bold;
}
.onBoardingContainer .ant-timeline-item{
  padding-bottom:30px;
}
.onBoardingContainer .ant-timeline-item-head-blue {
  margin-top: 6px;
  color: #1890ff;
  border-color: #1890ff;
  width: 13px;
  height: 13px;
  left: -2px;
}
.ant-card.cardWarning{
  border:1px solid #ffbb96;
  -webkit-box-shadow: 0px 0px 17px -1px rgba(255,187,150,0.43);
  -moz-box-shadow: 0px 0px 17px -1px rgba(255,187,150,0.43);
  box-shadow: 0px 0px 17px -1px rgba(255,187,150,0.43);
}

.ant-card.cardWarning .widgetTitle{
  color:#d4380d;
}
.cardRow{
  margin-bottom:7px;
}
.ant-btn{
  border-radius:20px;
}
.ant-layout-sider, .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub, .ant-layout-sider-zero-width-trigger,
.ant-layout.authLayout{
  background: #292333;
}
.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a{
 color:rgba(255, 255, 255, 0.80) 
}
.eventImageSmall{
  height: 60px;
  border-radius: 10px;
}
.chartTooltipContent{
  padding: 5px !important;
}
.chartTooltipContent h2{
  margin-top: 15px !important;
  margin-bottom: 20px;
}
.labelIcon{
  margin-right: 8px;
}
.ant-popover{
  max-width:300px;
}
.ant-drawer-title{
  font-size: 22px;
  font-weight: 700;
}
.popconfirmButton{
  margin-top:10px;
}
.auxItemLink{}
.auxItemLink span{
  margin-right:6px;
}
.menuItemIndent a{
  margin-left:15px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child){
  margin-bottom:5px;
  padding-top: 17px;
  padding-bottom:17px;
}
.ant-menu-item a{
  font-size: 15px;
}
.ant-menu-item-selected{
  background: #04c6d0 !important;
  background:#171e2b !important; 
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .anticon{
  color:#04c6d0;
}
.ant-menu-item-selected .ant-menu-title-content,
.ant-menu-item-selected a{
  font-weight: bold;
  color:#04c6d0;

}
.ant-menu-item-selected .ant-menu-title-content a,
.ant-menu-item-selected a,
.ant-pagination-item-active a,
.ant-pagination-item-active a:hover,
.ant-pagination-item:hover a{
  color:#04c6d0 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-switch-checked,
.ant-tree-checkbox-checked .ant-tree-checkbox-inner{
  background: #04c6d0 !important;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner{
  border-color:#10bbc4;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border: 1px solid #04c6d0;
}
.qrCodeTableIndex{
  width: 1690px;
  height:0px;
  overflow:hidden;
}
.qrCodeTableIndex .tableIndexContainer{
  text-align: center;
  font-size: 110px;
  font-family: 'Nunito';
  font-weight: 800;
  color:#292333;
  margin-bottom:30px;
}
.placecardThumbnail{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.iconSpacing{
  margin-left:7px;
}
.ant-drawer-header-title{
  flex-direction: row-reverse;
}
.ant-btn-lg{
  padding: 8px 15px;
}
.ant-btn:hover, .ant-btn:focus{
  color: #04c6d0;
  border-color: #04c6d0;
}
.ant-breadcrumb a:hover{
  color:#04c6d0;
}
.ant-btn-primary:hover:hover{
  color: #fff;
}
.ant-btn-primary span{
  font-weight: 600;
}
.ant-input:hover, .ant-input:focus, .ant-input-focused, .ant-pagination-item-active,
.ant-pagination-item:hover{
  border-color: #04c6d0;
}
.ant-input-affix-wrapper-lg{
  padding: 8px 11px;
}
.label-error{
  color: red;
  font-weight: bold;
}
.menu-group-label{
  margin-left:20px;
  text-transform: uppercase;
  font-size:11px;
  margin-bottom:15px;
  color:rgba(255, 255, 255, 0.35);
}
.sidebar-menu-tag{
  margin-left:15px;
}
.orderContent{}
.orderContentDepth-1{
  font-weight:600;
  margin-top:4px;
}
.orderContentDepth-2{
  margin-left:20px;
}
.orderContentDepth-3{
  margin-left:40px
}
.ant-drawer-close,
.ant-drawer-title{
  color:#282c34;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue{
  color: #09bcc5;
  background: #eaf7f8;
  border-color: #75e3e9;
  cursor: pointer;
}
.table-row-dark {
  background-color: #f8f8f8;
}
.nutritional-table{
  margin-bottom:30px;
}
.nutritional-name.main{
  font-weight: 600;
}
.nutritional-name.secondary{
  margin-left:20px;
  display:block;
}
.ingredient-select{
  width:600px !important;
}
.ingredient-select-text{
  font-size:13px;
}
.ingredient-select-title{
  font-weight:bold;
  font-size:15px;
  margin-bottom:1px;
}
.nutritional-table .ant-input-number-group-wrapper{

  margin-bottom: 5px;
}
.ant-table-thead > tr > th{
  font-weight:600;
}
.nutritional-table .inputTitle{
  width:100px;
}
.nutritional-table.calculation .ant-input-number{
  width:65px;
}
.nutritional-table.calculation .ant-table-thead > tr > th{
  width:70px;
  font-size:13px;
}
.nutritional-table.calculation .ant-table-thead > tr > th.column-large{
  width:auto;
  min-width:160px;
}
.nutritional-table.calculation .ant-table-tbody > tr > td{
  vertical-align: top;
  max-width:300px;
}
.nutritional-table.result-total{
  border:1px solid #efefef;

}
.nutritional-table.result-100g{
  border:1px solid #04c6d0;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.link-action{
  font-size:14px;
  font-weight:bold;
}
.button-icon-left{
  margin-left:10px;
}
.button-noHover:hover, .button-noHover:focus, .button-noHover:active{
  border:0;
}

.nutritional-table.calculation .ant-table-footer{
  border-top:2px solid #09bcc5;
  border-bottom:2px solid #09bcc5;
}
.ant-form-large .ant-form-item-control-input{
  align-items:flex-start;
}
.ant-form-large .ant-form-item-label > label{
  font-weight:600;
}
.ant-select-item-group{
  color:#09bcc5;
  font-weight: 500;
  font-size: 13px;
}
.rc-virtual-list .ant-select-item.ant-select-item-group:nth-of-type(1){
  margin-top:10px;
}
.nutritional-preview{
  margin-bottom:15px; 
}
.nutritional-preview .inner{
  margin-bottom:15px;
  border:1px solid #04c6d0;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  padding:15px;
  cursor:pointer;
}
.nutritional-preview.short .inner{
  border:1px solid #efefef;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 12px;
}
.nutritional-preview h3{
  font-weight:500;

}
.nutritional-preview .menuItem-title{
  margin-bottom:5px;
}
.nutritional-preview h5{
  margin-top:7px;
  font-size:14px;
  margin-bottom:3px;
}
.nutritional-preview .menuItem-title h3{
  font-size:17px;
  font-weight:600;
}
.nutritional-table-container{
  margin-top:15px;
}
.nutritional-table-container h3{
  margin-bottom:12px;
}
.nutritional-preview-container .ant-tabs-nav{
  margin-bottom:0px;
}
.nutritional-table-action{
  margin-top:10px;
  display: block;
}
h2 .anticon{
  font-size:23px;
  color:#282c34
}
.ant-alert-info{
  border-radius:7px;
}
.schedules-item{
  margin-bottom:10px;
  align-items: center;
}
.schedules-item-separator{
  border-bottom:1px solid #efefef;
  max-width:500px;
  margin-bottom:20px;
  margin-top:15px;
}
.timePicker-container{
  margin-bottom: 10px;
}
.timePicker-container .ant-form-item{
  margin-bottom:0px;
}
.timePicker-container .ant-picker-range{

}
.timePicker-container .ant-form-item-control-input{
align-items:center;
}
.schedules-item-select{
  min-width:100px;

}
.schedules-item .ant-select-selector{
  border-radius:7px !important;
}
.integration-selector{}
.integration-selector .ant-input-group-addon .ant-btn-icon-only.ant-btn-lg{
  border:0;
  background-color: transparent;
  height:30px;
  width:23px;
  padding:1px;
  margin-left:0px;
}
.customize-QR-group{
  display:flex;
  flex-direction: row;
  margin:10px 0px 15px 0px;
  align-items:center;
}
.color-picker-QR{
  background: #efefef;
  height: 2rem;
  width: 2rem;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}
.color-picker-QR:after {

}
.color-picker-QR.accent-white{
  border:1px solid #cecece;
  background-color: #fff;
}
.color-picker-QR.accent-red{
  background-color: #C32E32;
}
.color-picker-QR.accent-green{
  background-color: #125E39;
}
.color-picker-QR.accent-blue{
  background-color: #0F4C85;
}
.color-picker-QR.accent-yellow{
  background-color: #F3D11B;
}
.color-picker-QR.accent-black{
  background-color: #000000;
}
.color-picker-QR.accent-gray{
  background-color: #7f7f7f;
}
.color-picker-QR.accent-magenta{
  background-color: #9010A5;
}
.color-picker-QR.accent-beige{
  background-color: #F6EFD6;
}
.color-picker-QR.accent-active{
  border:2px solid #09bcc5;
  height: 2.1rem;
  width: 2.1rem;
}

.customized-QR-container{
 
  height:2390px;
  width:1690px; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position:relative;
}
.customized-QR-container.preview{
  border:1px solid #efefef;
  height:597.5px;
  width:422.5px;
}
.customized-QR-container::before{

}
.customized-QR-content{
  background-image:url('./assets/img/qr-customized-template.png');
  background-size: cover;
  width: 1256px;
  height:1726px;
  z-index:2;
}
.customized-QR-container.preview .customized-QR-content{
  background-image:url('./assets/img/qr-customized-template-preview.png');
  width: 308px;
  height: 425.5px;
}
.customized-QR-container.preview .customized-QR-content.qr-value{
  background-image:url('./assets/img/qr-customized-template.png');
  width: 308px;
  height: 425.5px;
}
.customized-QR-value{
  position:absolute;
  z-index:3;
}
.customized-QR-text{
  position:absolute;
  z-index:2;
}
.customized-QR-container.preview .customized-QR-text.text-title{
  top:127px;
  font-size:25px;
  font-weight: 700;
  width:220px;
  text-align: center;
  line-height: 32px;
}
.customized-QR-container .customized-QR-text.text-title{
  top: 500px;
  font-size: 102px;
  font-weight: 700;
  width: 975px;
  text-align: center;
  line-height: 124px;
}
.customized-QR-container .customized-QR-text.text-title.text-short{
  top:625px;
}
.customized-QR-container.preview .customized-QR-text.text-title.text-short{
  top:153px;
}
.customized-QR-container.preview .customized-QR-text.text-highlight{
  position:relative;
  font-size:32px;
  font-weight: 800;
}
.customized-QR-container .customized-QR-text.text-highlight{
  position: relative;
  font-size: 131px;
  font-weight: 800;
}
.customized-QR-container.preview .customized-QR-text.text-subTitle{
  bottom:177px;
  font-size:15px;
  font-weight: 500;
}
.customized-QR-container .customized-QR-text.text-subTitle{
  bottom: 690px;
  font-size: 62px;
  font-weight: 500;
}
.customized-QR-container.preview .customized-QR-text.text-bottom{
  bottom:110px;
  display: flex;
  font-size:13px;
  align-items: center;
}
.customized-QR-container .customized-QR-text.text-bottom{
  bottom: 435px;
  display: flex;
  font-size: 45px;
  align-items: center;
}
.customized-QR-container.preview .customized-QR-text.text-bottom-highlight{
  position:relative;
  font-size:22px;
  font-weight: 600;
  margin-right:7px;
}
.customized-QR-container .customized-QR-text.text-bottom-highlight{
  position: relative;
  font-size: 77px;
  font-weight: 600;
  margin-right: 45px;
}
.customized-QR-logo{
  width:50px;
  height:50px;
  position: absolute;
  z-index:2;
}
.customized-QR-bg-layer{
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}
.ant-switch{
  margin-right:10px;
}
